body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDataGrid-footerContainer .MuiTablePagination-root .MuiInputBase-root {
  /* hack to fix broken 'rows per page' select size inside DataGrid  */
  width: inherit !important;
}

.MuiDataGrid-toolbarContainer {
  margin-bottom: 15px;

  .MuiBox-root {
    /* hack to move DataGrid 'quick filter' input to the left*/
    flex: none;
  }
}

.MuiSwitch-switchBase.Mui-checked {
  transform: translate(16px, -12px) !important;
}
